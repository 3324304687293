body {
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

.App {
  font-family: Nunito Sans, serif;
  background-image: url("./Background.png");
  background-repeat: no-repeat;
  background-size: auto;
  width: 375px;
  height: 812px;
  display: flex;
  justify-content: center;
}

.Content {
  background-image: url("./Rectangle.png");
  width: 299px;
  height: 176px;
  border-radius: 16px;
  transform: translateY(150%);
}

.Paragraph {
  padding: 0 35px;
  transform: translateY(20%);
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.0025em;
}

.Title {
  font-weight: 700;
}
